const SearchData = [
    { id: 1, title: 'Home', url: 'https://www.jackiewyers.beauty/' },
    { id: 2, title: 'About', url: 'https://www.jackiewyers.beauty/about' },
    { id: 3, title: 'Sitemap', url: 'https://www.jackiewyers.beauty/sitemap' },
    { id: 4, title: 'Press', url: 'https://www.jackiewyers.beauty/press' },
    { id: 5, title: 'Subscribe', url: 'https://www.jackiewyers.beauty/subscribe' },
    { id: 6, title: 'Quiz', url: 'https://www.jackiewyers.beauty/quiz' },
    { id: 7, title: 'Vlogs', url: 'https://www.jackiewyers.beauty/vlogs' },
    { id: 8, title: 'Travel', url: 'https://www.jackiewyers.beauty/travel' },
    { id: 9, title: 'Privacy', url: 'https://www.jackiewyers.beauty/privacy' },
    { id: 10, title: 'Fan Art', url: 'https://www.jackiewyers.beauty/fan-art' },
    { id: 11, title: 'Tutorials', url: 'https://www.jackiewyers.beauty/tutorials' },
    { id: 12, title: 'Pop Culture', url: 'https://www.jackiewyers.beauty/popculture' },
    { id: 13, title: 'Time Travel Tutorials', url: 'https://www.jackiewyers.beauty/timetraveltutorials' },
    { id: 14, title: 'Trends', url: 'https://www.jackiewyers.beauty/trends' },
    { id: 15, title: 'Margot Barbie', url: 'https://www.jackiewyers.beauty/margotbarbie' },
    { id: 16, title: 'Latte Makeup', url: 'https://www.jackiewyers.beauty/lattemakeup' },
    { id: 17, title: 'Sugarplum Fairy', url: 'https://www.jackiewyers.beauty/sugarplumfairy' },
    { id: 18, title: 'Sharon Tate Bridal', url: 'https://www.jackiewyers.beauty/sharontatebridal' },
    { id: 19, title: 'Iconic Sharon', url: 'https://www.jackiewyers.beauty/iconicsharon' },
    { id: 20, title: 'Sophia Loren', url: 'https://www.jackiewyers.beauty/sophialoren' },
    { id: 21, title: 'Lana Blog', url: 'https://www.jackiewyers.beauty/lanablog' },
    { id: 22, title: 'Pat McGrath', url: 'https://www.jackiewyers.beauty/patmcgrath' },
    { id: 23, title: 'Victoria Secret', url: 'https://www.jackiewyers.beauty/victoriasecret' },
    { id: 24, title: '2016 Makeup', url: 'https://www.jackiewyers.beauty/2016Makeup' },
    { id: 25, title: 'Twiggy', url: 'https://www.jackiewyers.beauty/twiggy' },
    { id: 26, title: 'Yardley 1965', url: 'https://www.jackiewyers.beauty/yardley1965' },
    { id: 27, title: 'Grace Kelly', url: 'https://www.jackiewyers.beauty/gracekelly' },
    { id: 28, title: 'Balke Blog', url: 'https://www.jackiewyers.beauty/balkeblog' },
    { id: 29, title: 'No Makeup Spring', url: 'https://www.jackiewyers.beauty/nomakeupspring' },
    { id: 30, title: 'Bardot Hairstyles', url: 'https://www.jackiewyers.beauty/bardothairstyles' },
    { id: 31, title: 'Animal Pretty', url: 'https://www.jackiewyers.beauty/animalpretty' },
    { id: 32, title: 'Summer Beauty', url: 'https://www.jackiewyers.beauty/summerbeauty' },  
    { id: 33, title: 'Bridal Makeup', url: 'https://www.jackiewyers.beauty/bridalmakeup' },
  { id: 34, title: 'Bridal', url: 'https://www.jackiewyers.beauty/bridal' },
  { id: 35, title: 'Period Drama', url: 'https://www.jackiewyers.beauty/perioddrama' },
  { id: 36, title: 'Summer Inspo', url: 'https://www.jackiewyers.beauty/summerinspo' },
  { id: 37, title: 'Camera Settings', url: 'https://www.jackiewyers.beauty/camera-settings' },
  { id: 38, title: 'Francesca Bridgerton', url: 'https://www.jackiewyers.beauty/francesca-bridgerton' },
  { id: 39, title: 'Emily in Paris', url: 'https://www.jackiewyers.beauty/emily-in-paris' },
  { id: 40, title: 'Barbie Doll', url: 'https://www.jackiewyers.beauty/barbie-doll' },
  { id: 41, title: 'Barbie Roundup', url: 'https://www.jackiewyers.beauty/barbie-roundup' },
  { id: 42, title: 'Girly Pop Halloween', url: 'https://www.jackiewyers.beauty/girly-pop-halloween' },
  { id: 43, title: 'My Style', url: 'https://www.jackiewyers.beauty/mystyle' },
  { id: 44, title: 'Vintage Vibes Merch', url: 'https://www.jackiewyers.beauty/vintagevibesmerch' },
  { id: 45, title: '90s Vibes', url: 'https://www.jackiewyers.beauty/90sVibes' },
  { id: 46, title: 'Leather and Lace', url: 'https://www.jackiewyers.beauty/leatherandlace' },
  { id: 47, title: 'Retro Floral', url: 'https://www.jackiewyers.beauty/retrofloral' },
  { id: 48, title: 'Barbie Skiing', url: 'https://www.jackiewyers.beauty/barbieskiing' },
  { id: 49, title: 'Black and Leather', url: 'https://www.jackiewyers.beauty/blackandleather' },
  { id: 50, title: 'Cabin Fever', url: 'https://www.jackiewyers.beauty/cabinfever' },
  { id: 51, title: 'Manhattan Cool', url: 'https://www.jackiewyers.beauty/manhattancool' },
  { id: 52, title: 'Fall Little Black Dress', url: 'https://www.jackiewyers.beauty/falllbd' },
  { id: 53, title: 'Dark Florals', url: 'https://www.jackiewyers.beauty/darkflorals' },
  { id: 54, title: 'Central Park Summer', url: 'https://www.jackiewyers.beauty/cpsummer' },
  { id: 55, title: 'Sleek Spring', url: 'https://www.jackiewyers.beauty/sleekspring' },
  { id: 56, title: 'Paris Princess', url: 'https://www.jackiewyers.beauty/parisprincess' },
  { id: 57, title: 'Parisian Chic', url: 'https://www.jackiewyers.beauty/parisianchic' },
  { id: 58, title: 'Vatican Slay', url: 'https://www.jackiewyers.beauty/vaticanslay' },
  { id: 59, title: 'Walking Waiheke', url: 'https://www.jackiewyers.beauty/walkingwaiheke' },
  { id: 60, title: 'Princesscore Paris', url: 'https://www.jackiewyers.beauty/princesscoreparis' },
  { id: 61, title: 'Polka Dot', url: 'https://www.jackiewyers.beauty/polkadot' },
  { id: 62, title: 'Trevi Fountain', url: 'https://www.jackiewyers.beauty/trevifountain' },
  { id: 63, title: 'Summerset', url: 'https://www.jackiewyers.beauty/summerset' },
  { id: 64, title: 'Houndstooth', url: 'https://www.jackiewyers.beauty/houndstooth' },
  { id: 65, title: 'Met Steps', url: 'https://www.jackiewyers.beauty/metsteps' },
  { id: 66, title: 'Paris in NYC', url: 'https://www.jackiewyers.beauty/paris-in-nyc' },
  { id: 67, title: 'Floral Mini', url: 'https://www.jackiewyers.beauty/floral-mini' },
  { id: 68, title: 'Folklore Fairy', url: 'https://www.jackiewyers.beauty/folklore-fairy' },
  { id: 69, title: 'Mont Tremblant', url: 'https://www.jackiewyers.beauty/tremblant' },
  { id: 70, title: 'Hotel Quintessence', url: 'https://www.jackiewyers.beauty/hotelquintessancesummer' },
  { id: 71, title: 'Disney Beach Club Resort', url: 'https://www.jackiewyers.beauty/disneybeachclubresort' },
  { id: 72, title: 'EPCOT', url: 'https://www.jackiewyers.beauty/epcot' },
  { id: 73, title: 'Westgate San Diego', url: 'https://www.jackiewyers.beauty/westgatesandiego' },
  { id: 74, title: 'Andaz Maui', url: 'https://www.jackiewyers.beauty/andazmaui' },
  { id: 75, title: 'Prince of Wales', url: 'https://www.jackiewyers.beauty/princeofwales' },


    { id: 108, title: 'Barbie Beauty Makeup Looks', url: '/barbie-roundup' },
    { id: 109, title: 'Peaches ‘N Cream Barbie Makeup', url: '/barbie-roundup' },
    { id: 110, title: 'Roller Skate Barbie Makeup Tutorial', url: '/barbie-roundup' },
    { id: 111, title: 'Malibu Barbie Aesthetic', url: '/barbie-roundup' },
    { id: 112, title: 'Cherry Pie Picnic Barbie Look', url: '/barbie-roundup' },
    { id: 113, title: 'Brunette Barbiecore Makeup', url: '/barbie-roundup' },
    { id: 114, title: 'Original Barbie Vintage Glamour', url: '/barbie-roundup' },
    { id: 115, title: 'Barbie Movie Makeup', url: '/barbie-roundup' },
    { id: 117, title: 'Pastel Eyeshadow Makeup', url: '/barbie-roundup' },
    { id: 118, title: 'Iconic Doll Transformations', url: '/barbie-roundup' },
    { id: 119, title: 'Barbie Halloween Costume Ideas', url: '/barbie-roundup' },
    { id: 120, title: 'Bold Red Lips Makeup', url: '/barbie-roundup' },
    { id: 121, title: 'Barbie-Inspired Hairstyles', url: '/barbie-roundup' },
    { id: 122, title: 'Nostalgic Barbie Fashion', url: '/barbie-roundup' },
    { id: 123, title: 'Vintage Barbie-Inspired Looks', url: '/barbie-roundup' },
    { id: 124, title: 'Margot Robbie Barbie Makeup Recreation', url: '/barbie-roundup' },
    { id: 125, title: 'Pastel Florals for Barbiecore', url: '/barbie-roundup' },
    { id: 126, title: 'Bold Accessories for Barbiecore', url: '/barbie-roundup' },
    { id: 127, title: 'Nostalgic 90s Barbie Style', url: '/barbie-roundup' },
    { id: 128, title: 'Doll-Inspired Makeup Tutorials', url: '/barbie-roundup' },
    { id: 129, title: 'Glamorous Barbie Makeup', url: '/barbie-roundup' },
    { id: 130, title: 'Barbie Aesthetic Transformation', url: '/barbie-roundup' },
    { id: 131, title: 'Classic Barbie Makeup and Style', url: '/barbie-roundup' },
    { id: 132, title: 'Retro Chic Barbiecore Fashion', url: '/barbie-roundup' },
    { id: 133, title: 'Barbie-Inspired Beauty Tips', url: '/barbie-roundup' },
    { id: 134, title: 'Pink Makeup for Barbiecore', url: '/barbie-roundup' },
    { id: 135, title: '50s Barbie Looks', url: '/barbie-roundup' },
    { id: 136, title: 'Iconic Doll Fashion Trends', url: '/barbie-roundup' },
    { id: 137, title: 'Step-by-Step Barbie Tutorials', url: '/barbie-roundup' },
        { id: 139, title: 'Top Barbie Collaborations 2024', url: '/barbie-doll' },
        { id: 140, title: 'Must-Have Barbie Products', url: '/barbie-doll' },
        { id: 141, title: 'Adult Barbie Collectors', url: '/barbie-doll' },
        { id: 142, title: 'Barbie x Stanley', url: '/barbie-doll' },
        { id: 143, title: 'Barbie x FUNBOY', url: '/barbie-doll' },
        { id: 144, title: 'Silkstone Barbie', url: '/barbie-doll' },
        { id: 145, title: 'Barbie x Aldo', url: '/barbie-doll' },
        { id: 146, title: 'Barbie Coffee Table Book', url: '/barbie-doll' },
        { id: 147, title: 'Barbiecore Trends', url: '/barbie-doll' },
        { id: 149, title: 'Barbie Fashion Accessories', url: '/barbie-doll' },
        { id: 150, title: 'Barbie Collectibles 2024', url: '/barbie-doll' },

        { id: 151, title: 'How to Take Better Photos with iPhone 13 Pro Max', url: '/camera-settings' },
        { id: 152, title: 'Best iPhone Camera Settings for Photography', url: '/camera-settings' },
        { id: 153, title: 'Vibrant Photo Settings for iPhone', url: '/camera-settings' },
        { id: 154, title: 'Rule of Thirds Photography Tips', url: '/camera-settings' },
        { id: 155, title: 'iPhone HDR Mode On vs Off', url: '/camera-settings' },
        { id: 156, title: 'Using Portrait Mode on iPhone for Selfies', url: '/camera-settings' },
        { id: 157, title: 'Capturing Night Photos with iPhone 13', url: '/camera-settings' },
        { id: 158, title: 'Natural Light Photography Tips for Mobile', url: '/camera-settings' },
        { id: 159, title: 'Mobile Photography Tips for Beginners', url: '/camera-settings' },
        { id: 160, title: 'Enhancing Mobile Photos with Grid Composition', url: '/camera-settings' },
        { id: 161, title: 'Cleaning iPhone Lens for Sharper Photos', url: '/camera-settings' },
        { id: 162, title: 'Golden Hour Photography with iPhone', url: '/camera-settings' },
        { id: 163, title: 'Optimizing iPhone Photos for Instagram', url: '/camera-settings' },
        { id: 164, title: 'iPhone 13 Pro Max Travel Photography Settings', url: '/camera-settings' },
        { id: 165, title: 'Mobile Editing Tips for Vibrant Photos', url: '/camera-settings' },
        { id: 166, title: 'Canon G7x for Nighttime Photography', url: '/camera-settings' },
        { id: 167, title: 'iPhone 13 Pro Max vs Canon G7x Photo Quality', url: '/camera-settings' },
        { id: 168, title: 'Mastering Low-Light iPhone Photography', url: '/camera-settings' },
        { id: 169, title: 'iPhone Bokeh Effect Photography Tips', url: '/camera-settings' },
        { id: 170, title: 'iPhone Camera Settings for Soft Lighting', url: '/camera-settings' },
            { id: 171, title: 'How to Create a Dragon Braid', url: '/articles/dragon-braid' },
            { id: 172, title: 'Step-by-Step Pull-Through Braid Tutorial', url: '/articles/dragon-braid' },
            { id: 173, title: 'Dragon Braid Tutorial for Long Hair', url: '/articles/dragon-braid' },
            { id: 174, title: 'Braided Hairstyles for Beginners', url: '/articles/dragon-braid' },
            { id: 175, title: 'Easy Voluminous Braid Hairstyles', url: '/articles/dragon-braid' },
            { id: 176, title: 'Faux French Braid Tutorial', url: '/articles/dragon-braid' },
            { id: 177, title: 'Pull-Through Braid with Rubber Bands', url: '/articles/dragon-braid' },
            { id: 178, title: 'Dragon Braid Ideas for Weddings', url: '/articles/dragon-braid' },
            { id: 179, title: 'Long Hair Braid Tutorial 2024', url: '/articles/dragon-braid' },
            { id: 180, title: 'Voluminous Braid Hairstyles', url: '/articles/dragon-braid' },
            { id: 181, title: 'Emily in Paris Fashion Evolution', url: '/emily-in-paris' },
  { id: 182, title: 'Iconic Outfits from Emily in Paris', url: '/emily-in-paris' },
  { id: 183, title: 'Emily Cooper Style Guide', url: '/emily-in-paris' },
  { id: 184, title: 'Makeup Looks from Emily in Paris', url: '/emily-in-paris' },
  { id: 185, title: 'Lily Collins as Emily Cooper', url: '/emily-in-paris' },
  { id: 186, title: 'Patricia Field Fashion Design', url: '/emily-in-paris' },
  { id: 187, title: 'Marylin Fitoussi Costume Styling', url: '/emily-in-paris' },
  { id: 188, title: 'American-in-Paris Chic Outfits', url: '/emily-in-paris' },
  { id: 189, title: 'Bold and Eclectic Fashion Inspiration', url: '/emily-in-paris' },
  { id: 190, title: 'Netflix Fashion Series Style', url: '/emily-in-paris' },
  { id: 191, title: 'Emily in Paris Hair and Makeup Tips', url: '/emily-in-paris' },
  { id: 192, title: 'Audrey Hepburn-Inspired Outfits', url: '/emily-in-paris' },
  { id: 193, title: 'Vibrant Parisian Fashion', url: '/emily-in-paris' },
  { id: 194, title: 'Recreating Emily Cooper’s Style', url: '/emily-in-paris' },
  { id: 195, title: 'Season 1-4 Fashion Recap', url: '/emily-in-paris' },
  { id: 196, title: 'Makeup and Hair Tutorials from Emily in Paris', url: '/emily-in-paris' },
  { id: 197, title: 'TV Show-Inspired Beauty', url: '/emily-in-paris' },
  { id: 198, title: 'Emily in Paris Green Cardigan Style', url: '/emily-in-paris' },
  { id: 199, title: 'Emily Cooper Bold Lipstick Shades', url: '/emily-in-paris' },
  { id: 200, title: 'Thrifting Emily in Paris-Inspired Outfits', url: '/emily-in-paris' },
  { id: 201, title: 'Budget-Friendly Parisian Fashion Looks', url: '/emily-in-paris' },
  { id: 202, title: 'Emily in Paris Season Four Fashion Preview', url: '/emily-in-paris' },
 
    { id: 224, title: 'Girly Halloween Costumes 2024', url: '/girly-pop-halloween' },
    { id: 225, title: 'Britney Spears Schoolgirl Halloween Look', url: '/girly-pop-halloween' },
    { id: 226, title: 'Barbiecore Halloween Ideas', url: '/girly-pop-halloween' },
    { id: 227, title: 'Bridgerton Costume Inspiration', url: '/girly-pop-halloween' },
    { id: 228, title: 'Ariel Little Mermaid Halloween Style', url: '/girly-pop-halloween' },
    { id: 229, title: 'Taylor Swift Betty Folklore Outfit', url: '/girly-pop-halloween' },
    { id: 230, title: 'YSL Haute Couture Bride Recreation', url: '/girly-pop-halloween' },
    { id: 231, title: 'Audrey Hepburn Breakfast at Tiffany’s Costume', url: '/girly-pop-halloween' },
    { id: 232, title: 'Vintage Barbie 1950s Glam Look', url: '/girly-pop-halloween' },
    { id: 234, title: 'Pop Culture Inspired Halloween Costumes', url: '/girly-pop-halloween' },
    { id: 235, title: 'Nostalgic Halloween Outfits', url: '/girly-pop-halloween' },
    { id: 236, title: 'Retro Costume Ideas 2024', url: '/girly-pop-halloween' },
    { id: 237, title: 'Trendy Girly Halloween Styles', url: '/girly-pop-halloween' },
    { id: 238, title: 'DIY Halloween Makeup and Costumes', url: '/girly-pop-halloween' },
    { id: 239, title: 'Celebrity-Inspired Halloween Fashion', url: '/girly-pop-halloween' },
    { id: 240, title: 'Cottagecore Halloween Outfits', url: '/girly-pop-halloween' },
    { id: 241, title: 'Glamorous Halloween Looks', url: '/girly-pop-halloween' },
    { id: 242, title: 'Romantic Halloween Costume Ideas', url: '/girly-pop-halloween' },
    { id: 243, title: 'Halloween 2024 Beauty Trends', url: '/girly-pop-halloween' },
    { id: 271, title: 'Period Drama Makeup', url: '/perioddrama' },
    { id: 272, title: 'Historical Hairstyles', url: '/perioddrama' },
    { id: 273, title: 'No-Makeup Makeup', url: '/perioddrama' },
    { id: 274, title: 'Period Drama Hair Tutorial', url: '/perioddrama' },
    { id: 275, title: 'Tudor Core Aesthetic', url: '/perioddrama' },
    { id: 277, title: 'Historical Makeup Inspiration', url: '/perioddrama' },
    { id: 278, title: 'Period Drama Beauty Trends', url: '/perioddrama' },
    { id: 279, title: '18th-Century Hairstyles', url: '/perioddrama' },
    { id: 280, title: 'Bridgerton-Inspired Makeup', url: '/perioddrama' },
    { id: 281, title: 'Victorian Hairstyles', url: '/perioddrama' },
    { id: 282, title: 'Edwardian Era Makeup', url: '/perioddrama' },
    { id: 283, title: 'Pirates of the Caribbean Hair Tutorial', url: '/perioddrama' },
    { id: 284, title: 'Little Women Makeup Tutorial', url: '/perioddrama' },
    { id: 285, title: 'Tuck Everlasting Hair and Makeup', url: '/perioddrama' },
    { id: 286, title: 'Blue Lagoon Inspired Hairstyles', url: '/perioddrama' },
    { id: 287, title: 'Historical Beauty Trends', url: '/perioddrama' },
    { id: 288, title: 'Vintage-Inspired Makeup Looks', url: '/perioddrama' },
    { id: 289, title: 'Timeless Makeup Styles', url: '/perioddrama' },
    { id: 290, title: 'Period Piece Beauty Recreations', url: '/perioddrama' },
    { id: 292, title: 'Period Makeup Tutorials', url: '/perioddrama' },
    { id: 293, title: 'Natural Makeup Inspiration', url: '/perioddrama' },
    { id: 294, title: 'Pride and Prejudice Beauty', url: '/perioddrama' },
    { id: 295, title: 'Historical Fiction Beauty Inspiration', url: '/perioddrama' },
    { id: 296, title: 'Rory Gilmore Makeup', url: '/articles/Rory-gilmore' },
  { id: 297, title: 'Gilmore Girls Style', url: '/articles/Rory-gilmore' },
  { id: 298, title: 'No-Makeup Makeup', url: '/articles/Rory-gilmore' },
  { id: 299, title: 'Cozy Fall Fashion', url: '/articles/Rory-gilmore' },
  { id: 300, title: 'Preppy Outfits', url: '/articles/Rory-gilmore' },
  { id: 301, title: 'Rory Gilmore Beauty Tips', url: '/articles/Rory-gilmore' },
  { id: 302, title: 'Stars Hollow Fashion', url: '/articles/Rory-gilmore' },
  { id: 303, title: 'Alexis Bledel Look', url: '/articles/Rory-gilmore' },
  { id: 304, title: 'Fall Wardrobe Inspiration', url: '/articles/Rory-gilmore' },
  { id: 305, title: 'Rory Gilmore Hairstyles', url: '/articles/Rory-gilmore' },
  { id: 306, title: 'Natural Beauty', url: '/articles/Rory-gilmore' },
  { id: 307, title: 'Light Makeup Tutorial', url: '/articles/Rory-gilmore' },
  { id: 308, title: 'Cozy Sweaters', url: '/articles/Rory-gilmore' },
  { id: 309, title: 'Plaid Skirts', url: '/articles/Rory-gilmore' },
  { id: 310, title: 'Preppy Style', url: '/articles/Rory-gilmore' },
  { id: 311, title: 'Bookish Aesthetic', url: '/articles/Rory-gilmore' },
  { id: 312, title: 'Gilmore Girls Filming Locations', url: '/articles/Rory-gilmore' },
  { id: 313, title: 'Unionville Ontario', url: '/articles/Rory-gilmore' },
  { id: 314, title: 'Rory Gilmore Inspired Look', url: '/articles/Rory-gilmore' },
  { id: 315, title: 'Skincare Routine', url: '/articles/Rory-gilmore' },
  { id: 316, title: 'K-Beauty Products', url: '/articles/Rory-gilmore' },
  { id: 317, title: 'Fall Outfits', url: '/articles/Rory-gilmore' },
  { id: 318, title: 'Layered Fashion', url: '/articles/Rory-gilmore' },
  { id: 319, title: 'Stars Hollow Travel', url: '/articles/Rory-gilmore' },
  { id: 320, title: '2000s TV Style', url: '/articles/Rory-gilmore' },
  { id: 321, title: 'Soft Natural Makeup', url: '/articles/Rory-gilmore' },
  { id: 322, title: 'Cherry ChapStick', url: '/articles/Rory-gilmore' },
  { id: 323, title: 'Rory Gilmore Wardrobe', url: '/articles/Rory-gilmore' },
  { id: 324, title: 'Luke’s Diner Vibe', url: '/articles/Rory-gilmore' },
  { id: 325, title: 'Bookish Chic', url: '/articles/Rory-gilmore' },
  { id: 326, title: 'Minimalist Makeup', url: '/articles/Rory-gilmore' },
  { id: 327, title: 'Fall Makeup Guide', url: '/articles/Rory-gilmore' },
  { id: 328, title: 'Gilmore Girls Beauty Tutorial', url: '/articles/Rory-gilmore' },
  { id: 329, title: 'Timeless Beauty Tips', url: '/articles/Rory-gilmore' },
  { id: 330, title: 'Rory Gilmore Outfits', url: '/articles/Rory-gilmore' },
  { id: 331, title: 'Gilmore Girls Fandom', url: '/articles/Rory-gilmore' },
  { id: 332, title: 'Beauty Influencer Tips', url: '/articles/Rory-gilmore' },
  { id: 334, title: 'TV Show Fashion Recreations', url: '/articles/Rory-gilmore' },
  { id: 395, title: "Victoria's Secret Fashion Show", url: '/Victoriasecret' },
  { id: 396, title: "Victoria's Secret Makeup", url: '/Victoriasecret' },
  { id: 397, title: 'Snow Angel Look', url: '/Victoriasecret' },
  { id: 398, title: '2013 Fashion Show', url: '/Victoriasecret' },
  { id: 399, title: 'Beauty Tips', url: '/Victoriasecret' },
  { id: 401, title: 'Makeup Tutorial', url: '/Victoriasecret' },
  { id: 402, title: 'Lingerie Fashion Show', url: '/Victoriasecret' },
  { id: 403, title: 'Beauty Blog', url: '/Victoriasecret' },
  { id: 404, title: '2014 Beauty Products', url: '/Victoriasecret' },
  { id: 405, title: 'Taylor Swift VSFS', url: '/Victoriasecret' },
  { id: 406, title: 'Iconic Beauty Looks', url: '/Victoriasecret' },
  { id: 407, title: 'Girly Tumblr Vibe', url: '/Victoriasecret' },
  { id: 408, title: 'Bronzed Skin', url: '/Victoriasecret' },
  { id: 409, title: "Victoria's Secret Glosses", url: '/Victoriasecret' },
  { id: 410, title: 'COCO & EVE Self Tan', url: '/Victoriasecret' },
  { id: 411, title: 'Bed-Head Blowout', url: '/Victoriasecret' },
  { id: 412, title: 'Too Faced Hangover Primer', url: '/Victoriasecret' },
  { id: 413, title: 'Luminous Silk Foundation', url: '/Victoriasecret' },
  { id: 414, title: 'Armani Beauty', url: '/Victoriasecret' },
  { id: 415, title: 'Hoola Bronzer', url: '/Victoriasecret' },
  { id: 416, title: 'NARS Orgasm Blush', url: '/Victoriasecret' },
  { id: 417, title: 'Charlotte Tilbury Highlighter', url: '/Victoriasecret' },
  { id: 418, title: "Victoria's Secret Robes", url: '/Victoriasecret' },
  { id: 419, title: 'VSFS 2013 DIY Looks', url: '/Victoriasecret' },
  { id: 420, title: 'Girly Aesthetic Trends', url: '/Victoriasecret' },
  { id: 421, title: 'Nostalgic Beauty Tutorials', url: '/Victoriasecret' },
  { id: 422, title: '2013 Runway-Inspired Makeup', url: '/Victoriasecret' },
  { id: 424, title: 'Winter Glow Makeup Trends', url: '/Victoriasecret' },
  { id: 425, title: 'Angel Costume Recreations', url: '/Victoriasecret' },
  { id: 426, title: 'Pinterest-Inspired Beauty Tips', url: '/Victoriasecret' },
  { id: 427, title: "Victoria's Secret Fragrances", url: '/Victoriasecret' },
  { id: 428, title: 'VSFS Signature Hairstyles', url: '/Victoriasecret' },
  { id: 429, title: 'Top Wicked Collaborations 2024', url: '/articles/wicked' },
  { id: 430, title: 'Must-Have Wicked Products', url: '/articles/wicked' },
  { id: 431, title: 'Wicked Merchandise 2024', url: '/articles/wicked' },
  { id: 432, title: 'Wicked Movie Merchandise', url: '/articles/wicked' },
  { id: 433, title: 'Wicked Fan Gifts', url: '/articles/wicked' },
  { id: 435, title: 'Oz-Inspired Fashion', url: '/articles/wicked' },
  { id: 436, title: 'Wicked Apparel', url: '/articles/wicked' },
  { id: 437, title: 'REM Beauty Wicked Collection', url: '/articles/wicked' },
  { id: 438, title: 'IT Cosmetics Wicked Brushes', url: '/articles/wicked' },
  { id: 439, title: 'Wicked Accessories', url: '/articles/wicked' },
  { id: 440, title: 'Ariana Grande Wicked', url: '/articles/wicked' },
  { id: 441, title: 'Cynthia Erivo', url: '/articles/wicked' },
  { id: 442, title: 'Wicked Collectibles', url: '/articles/wicked' },
  { id: 443, title: 'Elphaba Glinda Inspired Fashion', url: '/articles/wicked' },
  { id: 444, title: 'Holiday Gifts for Wicked Fans', url: '/articles/wicked' },
  { id: 445, title: 'Oz Jewelry', url: '/articles/wicked' },
  { id: 446, title: 'Wicked Luggage', url: '/articles/wicked' },
  { id: 447, title: 'Fantasy Fashion', url: '/articles/wicked' },
  { id: 448, title: 'Wizard of Oz Inspired', url: '/articles/wicked' },
  { id: 449, title: 'Beis Wicked Luggage', url: '/articles/wicked' },
  { id: 450, title: 'Vera Bradley Wicked Prints', url: '/articles/wicked' },
  { id: 451, title: 'Rebecca Minkoff Wicked Collection', url: '/articles/wicked' },
  { id: 452, title: 'Loungefly Wicked Bags', url: '/articles/wicked' },
  { id: 453, title: 'Marks and Spencer Holiday Ornaments', url: '/articles/wicked' },
  { id: 454, title: 'Voluspa Wicked Candles', url: '/articles/wicked' },
  { id: 455, title: 'Mattel Wicked Dolls', url: '/articles/wicked' },
  { id: 456, title: 'Aldo Pink Bow Heels', url: '/articles/wicked' },
  { id: 457, title: 'Cambridge Satchel Glinda Bag', url: '/articles/wicked' },
  { id: 458, title: 'Wicked-Themed Holiday Gifts', url: '/articles/wicked' },

  { id: 459, title: '2016 Makeup Trends', url: '/2016makeup' },
  { id: 460, title: 'Kylie Jenner Makeup', url: '/2016makeup' },
  { id: 462, title: 'Matte Makeup Looks', url: '/2016makeup' },
  { id: 463, title: 'Bold Glamour', url: '/2016makeup' },
  { id: 464, title: 'Makeup Tutorial', url: '/2016makeup' },
  { id: 465, title: 'Beauty Blog', url: '/2016makeup' },
  { id: 466, title: 'High-Coverage Foundations', url: '/2016makeup' },
  { id: 467, title: 'Dip Brows', url: '/2016makeup' },
  { id: 468, title: 'Mink Lashes', url: '/2016makeup' },
  { id: 469, title: 'Glittery Cut Creases', url: '/2016makeup' },
  { id: 470, title: 'Smoky Eyes', url: '/2016makeup' },
  { id: 471, title: 'Kylie Lip Kits', url: '/2016makeup' },
  { id: 472, title: 'Too Faced Sweet Peach Palette', url: '/2016makeup' },
  { id: 473, title: 'Laura Mercier Setting Powder', url: '/2016makeup' },
  { id: 474, title: 'Becca Champagne Pop', url: '/2016makeup' },
  { id: 475, title: 'Jaclyn Hill Makeup', url: '/2016makeup' },
  { id: 476, title: 'IG Baddie Makeup', url: '/2016makeup' },
  { id: 477, title: 'Contouring and Highlighting', url: '/2016makeup' },
  { id: 478, title: 'Retro Beauty', url: '/2016makeup' },
  { id: 479, title: 'Flash Photography Makeup Tips', url: '/2016makeup' },
  { id: 480, title: 'Makeup Influencer Trends', url: '/2016makeup' },
  { id: 481, title: 'Anastasia Dip Brow', url: '/2016makeup' },
  { id: 482, title: 'Tarte Shape Tape', url: '/2016makeup' },
  { id: 483, title: 'Benefit Hoola Bronzer', url: '/2016makeup' },
  { id: 484, title: 'Ben Nye Luxury Powder', url: '/2016makeup' },
  { id: 485, title: 'Painted By Spencer', url: '/2016makeup' },
  { id: 486, title: 'Kylie Jenner Lip Combos', url: '/2016makeup' },
  { id: 487, title: 'Animal Pretty Makeup', url: '/animalpretty' },
  { id: 489, title: 'Deer Pretty Makeup', url: '/animalpretty' },
  { id: 490, title: 'Cat Pretty Makeup', url: '/animalpretty' },
  { id: 491, title: 'Fox Pretty Makeup', url: '/animalpretty' },
  { id: 492, title: 'Bunny Pretty Makeup', url: '/animalpretty' },
  { id: 493, title: 'Doe-Eyed Makeup', url: '/animalpretty' },
  { id: 494, title: 'Cat Eye Look', url: '/animalpretty' },
  { id: 495, title: 'Fox-Inspired Makeup', url: '/animalpretty' },
  { id: 496, title: 'Bunny-Inspired Beauty', url: '/animalpretty' },
  { id: 497, title: 'Natural Blush Tips', url: '/animalpretty' },
  { id: 498, title: 'Winged Liner Tutorial', url: '/animalpretty' },
  { id: 499, title: 'Seasonal Color Palettes Makeup', url: '/animalpretty' },
  { id: 500, title: 'Soft Glam Makeup', url: '/animalpretty' },
  { id: 501, title: 'Bold Eye Looks', url: '/animalpretty' },
  { id: 502, title: 'Ethereal Makeup', url: '/animalpretty' },
  { id: 503, title: 'Smoky Eye Techniques', url: '/animalpretty' },
  { id: 504, title: 'Highlighter and Blush Tips', url: '/animalpretty' },
  { id: 505, title: 'Beauty Influencer Tutorials', url: '/animalpretty' },
  { id: 506, title: 'Fable England Accessories', url: '/animalpretty' },
  { id: 507, title: 'Animal-Inspired Fashion', url: '/animalpretty' },
  { id: 508, title: 'Bohemian Makeup Style', url: '/animalpretty' },
  { id: 509, title: 'Angelic Makeup Looks', url: '/animalpretty' },
  { id: 510, title: 'Trendy Makeup Guides', url: '/animalpretty' },
  { id: 511, title: 'How to Look Like a Deer, Fox, Cat, or Bunny', url: '/animalpretty' },
  { id: 512, title: 'Makeup for Doe Eyes', url: '/animalpretty' },
  { id: 513, title: 'Makeup for Winged Eyes', url: '/animalpretty' },
  { id: 514, title: 'Soft and Natural Lashes', url: '/animalpretty' },
  { id: 515, title: 'Bridal Makeup Trial', url: '/bridalmakeup' },
  { id: 517, title: 'Wedding Day Makeup Tips', url: '/bridalmakeup' },
  { id: 518, title: 'Bridal Skin Prep', url: '/bridalmakeup' },
  { id: 519, title: 'Charlotte Tilbury Foundation', url: '/bridalmakeup' },
  { id: 520, title: 'Rare Beauty Blush', url: '/bridalmakeup' },
  { id: 521, title: 'Bridal Contour Products', url: '/bridalmakeup' },
  { id: 522, title: 'Bridal Setting Spray', url: '/bridalmakeup' },
  { id: 523, title: 'Natural Bridal Makeup', url: '/bridalmakeup' },
  { id: 524, title: 'Waterproof Wedding Makeup', url: '/bridalmakeup' },
  { id: 525, title: 'Bridal Eye Makeup Tutorial', url: '/bridalmakeup' },
  { id: 526, title: 'DIY Bridal Glam', url: '/bridalmakeup' },
  { id: 527, title: 'Timeless Bridal Look', url: '/bridalmakeup' },
  { id: 528, title: 'Fairytale Bridal Makeup', url: '/bridalmakeup' },
  { id: 529, title: 'Wedding Beauty Inspiration', url: '/bridalmakeup' },
  { id: 530, title: 'Best Bridal Makeup Products', url: '/bridalmakeup' },
  { id: 531, title: 'Wedding Day Lashes', url: '/bridalmakeup' },
  { id: 532, title: 'Soft Bridal Makeup', url: '/bridalmakeup' },
  { id: 533, title: 'Bridal Lip Colors', url: '/bridalmakeup' },
  { id: 534, title: 'Iconic Nude Lipstick', url: '/bridalmakeup' },
  { id: 535, title: 'Champagne Bridal Makeup', url: '/bridalmakeup' },
  { id: 536, title: 'Princess-Inspired Bridal Glam', url: '/bridalmakeup' },
  { id: 537, title: 'Bridal Beauty Routine', url: '/bridalmakeup' },
  { id: 538, title: 'Wedding Day Glow', url: '/bridalmakeup' },
  { id: 539, title: 'Long-Lasting Bridal Makeup', url: '/bridalmakeup' },
  { id: 540, title: 'Margot Robbie Inspired Makeup', url: '/bridalmakeup' },
  { id: 541, title: 'Brooke Shields Brows', url: '/bridalmakeup' },
  { id: 542, title: 'Best Bridal Blush and Highlight', url: '/bridalmakeup' },
  { id: 543, title: 'Glamorous Wedding Look', url: '/bridalmakeup' },
  { id: 544, title: 'Wedding Makeup Essentials', url: '/bridalmakeup' },
  { id: 545, title: 'Photo-Ready Bridal Look', url: '/bridalmakeup' },
  { id: 546, title: 'Best Wedding Day Foundation', url: '/bridalmakeup' },
  { id: 547, title: 'Romantic Bridal Beauty', url: '/bridalmakeup' },
  { id: 548, title: 'Latte Makeup', url: '/lattemakeup' },
  { id: 550, title: 'Warm Toned Makeup', url: '/lattemakeup' },
  { id: 551, title: 'Fall Beauty Trends', url: '/lattemakeup' },
  { id: 552, title: 'Latte Makeup Tutorial', url: '/lattemakeup' },
  { id: 553, title: 'Cozy Makeup Look', url: '/lattemakeup' },
  { id: 554, title: 'Tanielle Jai Inspired', url: '/lattemakeup' },
  { id: 555, title: 'Caramel Smokey Eye', url: '/lattemakeup' },
  { id: 556, title: 'Faux Freckles Makeup', url: '/lattemakeup' },
  { id: 557, title: '90s Makeup Revival', url: '/lattemakeup' },
  { id: 558, title: 'No Foundation Glow', url: '/lattemakeup' },
  { id: 559, title: 'Latte Beauty Guide', url: '/lattemakeup' },
  { id: 560, title: 'Contour and Highlight Tips', url: '/lattemakeup' },
  { id: 561, title: 'Yellow-Based Bronzer', url: '/lattemakeup' },
  { id: 562, title: 'Hoola Caramel Bronzer', url: '/lattemakeup' },
  { id: 563, title: 'Golden Highlighter', url: '/lattemakeup' },
  { id: 564, title: 'Latte Makeup Tips', url: '/lattemakeup' },
  { id: 565, title: 'Minimalist Makeup Aesthetic', url: '/lattemakeup' },
  { id: 566, title: 'Golden Goddess Makeup', url: '/lattemakeup' },
  { id: 567, title: 'One/Size Visionary Palette', url: '/lattemakeup' },
  { id: 568, title: 'Fall Makeup Look', url: '/lattemakeup' },
  { id: 569, title: 'Tanned Makeup Style', url: '/lattemakeup' },
  { id: 570, title: 'Natural Brows Makeup', url: '/lattemakeup' },
  { id: 571, title: 'Milk Lips Trend', url: '/lattemakeup' },
  { id: 572, title: 'Latte Glam Makeup', url: '/lattemakeup' },
  { id: 573, title: 'Radiant Skin Glow', url: '/lattemakeup' },
  { id: 574, title: 'Bronzed Makeup Tips', url: '/lattemakeup' },
  { id: 575, title: 'Beauty Trends 2024', url: '/lattemakeup' },
  { id: 576, title: 'No-Makeup Makeup', url: '/perioddrama' },
  { id: 577, title: 'Spring Beauty Looks', url: '/perioddrama' },
  { id: 578, title: 'Elizabeth Bennet Makeup', url: '/perioddrama' },
  { id: 579, title: 'Pride & Prejudice Makeup', url: '/perioddrama' },
  { id: 580, title: 'Daphne Bridgerton Makeup', url: '/perioddrama' },
  { id: 581, title: 'Bridgerton Beauty', url: '/perioddrama' },
  { id: 582, title: 'Rose Dawson Makeup', url: '/perioddrama' },
  { id: 583, title: 'Titanic Makeup Tutorial', url: '/perioddrama' },
  { id: 584, title: 'Amy March Little Women Makeup', url: '/perioddrama' },
  { id: 585, title: 'Rosie Dunne Love, Rosie Makeup', url: '/perioddrama' },
  { id: 586, title: 'Alaska Young Makeup', url: '/perioddrama' },
  { id: 587, title: 'Looking for Alaska Beauty', url: '/perioddrama' },
  { id: 588, title: 'Winnie Foster Tuck Everlasting Makeup', url: '/perioddrama' },
  { id: 590, title: 'Pop Culture Beauty', url: '/perioddrama' },
  { id: 591, title: 'Spring Makeup Trends', url: '/perioddrama' },
  { id: 592, title: 'Natural Makeup Inspiration', url: '/perioddrama' },
  { id: 593, title: 'Fresh Spring Looks', url: '/perioddrama' },
  { id: 594, title: 'TV and Film Inspired Makeup', url: '/perioddrama' },
  { id: 595, title: 'Vintage Beauty Styles', url: '/perioddrama' },
  { id: 596, title: 'Subtle Makeup Techniques', url: '/perioddrama' },
  { id: 597, title: 'Character Inspired Makeup', url: '/perioddrama' },
  { id: 598, title: 'Minimalist Beauty Trends', url: '/perioddrama' },
  { id: 599, title: 'Romantic Makeup Looks', url: '/perioddrama' },
  { id: 600, title: 'Natural Glow Beauty', url: '/perioddrama' },
  { id: 601, title: 'Spring Aesthetic Makeup', url: '/perioddrama' },
  { id: 603, title: 'Pat McGrath Makeup', url: '/patmcgrath' },
  { id: 604, title: 'Porcelain Doll Makeup Tutorial', url: '/patmcgrath' },
  { id: 606, title: "Maison Margiela Spring/Summer '24", url: '/patmcgrath' },
  { id: 607, title: 'Glass Skin Technique', url: '/patmcgrath' },
  { id: 608, title: 'Airbrushed Peel-Off Mask', url: '/patmcgrath' },
  { id: 609, title: 'Divine Blush', url: '/patmcgrath' },
  { id: 610, title: 'Mothership Palette', url: '/patmcgrath' },
  { id: 611, title: 'Avant-Garde Makeup', url: '/patmcgrath' },
  { id: 612, title: 'Haute Couture Beauty', url: '/patmcgrath' },
  { id: 613, title: 'Runway Makeup Inspiration', url: '/patmcgrath' },
  { id: 614, title: 'Editorial Makeup Trends', url: '/patmcgrath' },
  { id: 615, title: 'Iconic Glass Skin Look', url: '/patmcgrath' },
  { id: 616, title: 'Victorian Doll Makeup', url: '/patmcgrath' },
  { id: 617, title: 'Backstage Beauty Secrets', url: '/patmcgrath' },
  { id: 618, title: 'Pat McGrath Labs', url: '/patmcgrath' },
  { id: 619, title: 'Sublime Perfection Foundation', url: '/patmcgrath' },
  { id: 620, title: 'Divine Skin Rose Essence', url: '/patmcgrath' },
  { id: 621, title: 'Doll-Like Makeup Aesthetic', url: '/patmcgrath' },
  { id: 622, title: 'Innovative Makeup Techniques', url: '/patmcgrath' },
  { id: 623, title: 'Makeup Artistry Inspiration', url: '/patmcgrath' },
  { id: 625, title: 'Sugar Plum Fairy Makeup Tutorial', url: '/sugarplumfairy' },
  { id: 627, title: 'Nutcracker Ballet Inspired Makeup', url: '/sugarplumfairy' },
  { id: 628, title: 'Whimsical Holiday Makeup', url: '/sugarplumfairy' },
  { id: 629, title: 'Festive Beauty Trends', url: '/sugarplumfairy' },
  { id: 630, title: 'Chrome Eyeshadow for Holidays', url: '/sugarplumfairy' },
  { id: 631, title: 'Sparkling Pink Lips', url: '/sugarplumfairy' },
  { id: 632, title: 'Hailey Bieber Makeup Look', url: '/sugarplumfairy' },
  { id: 633, title: 'Christmas Beauty Ideas', url: '/sugarplumfairy' },
  { id: 634, title: 'Holiday Makeup Trends 2024', url: '/sugarplumfairy' },
  { id: 635, title: 'Ballet-Inspired Makeup Ideas', url: '/sugarplumfairy' },
  { id: 636, title: 'Magical Fairy Makeup Tutorial', url: '/sugarplumfairy' },
  { id: 637, title: 'Glitter Makeup Looks', url: '/sugarplumfairy' },
  { id: 638, title: 'Soft Glam Makeup for Winter', url: '/sugarplumfairy' },
  { id: 639, title: 'Enchanting Makeup Styles', url: '/sugarplumfairy' },
  { id: 640, title: 'Chrome Flakes Eyeshadow Tutorial', url: '/sugarplumfairy' },
  { id: 641, title: 'Pastel Winter Beauty Trends', url: '/sugarplumfairy' },
  { id: 642, title: 'Fantasy Makeup Inspiration', url: '/sugarplumfairy' },
  { id: 643, title: 'Step-by-Step Holiday Makeup', url: '/sugarplumfairy' },
  { id: 645, title: 'Ethereal Holiday Glam', url: '/sugarplumfairy' },
  { id: 646, title: 'Christmas Makeup Trends', url: '/sugarplumfairy' },
  { id: 647, title: 'Whimsical Winter Looks', url: '/sugarplumfairy' },
  { id: 648, title: 'Trending Makeup for Christmas Parties', url: '/sugarplumfairy' },
  { id: 649, title: 'Glowing Holiday Makeup', url: '/sugarplumfairy' },
  { id: 650, title: 'Ina Balke Makeup', url: '/balkeblog' },
  { id: 651, title: '1960s Makeup Tutorial', url: '/balkeblog' },
  { id: 652, title: 'Vintage Makeup', url: '/balkeblog' },
  { id: 654, title: 'Ted Russell Photography', url: '/balkeblog' },
  { id: 655, title: '60s Beauty', url: '/balkeblog' },
  { id: 656, title: 'Classic Makeup Looks', url: '/balkeblog' },
  { id: 657, title: 'Retro Makeup', url: '/balkeblog' },
  { id: 658, title: 'Ina Balke Style', url: '/balkeblog' },
  { id: 659, title: 'How to Do 60s Makeup', url: '/balkeblog' },
  { id: 660, title: 'Vintage Beauty', url: '/balkeblog' },
  { id: 662, title: 'Vintage Glamour', url: '/balkeblog' },
  { id: 663, title: 'Old Hollywood Makeup', url: '/balkeblog' },
  { id: 664, title: 'Vintage Hair and Makeup', url: '/balkeblog' },
  { id: 665, title: 'Classic 60s Style', url: '/balkeblog' },
  { id: 666, title: 'Iconic Makeup Looks', url: '/balkeblog' },
  { id: 667, title: 'Bardot Bun Tutorial', url: '/bardothairstyles' },
  { id: 668, title: 'Brigitte Bardot Hairstyles', url: '/bardothairstyles' },
  { id: 669, title: '60s Hair Tutorial', url: '/bardothairstyles' },
  { id: 670, title: 'Vintage Updo Hairstyles', url: '/bardothairstyles' },
  { id: 671, title: 'Faux Bangs Without Cutting Hair', url: '/bardothairstyles' },
  { id: 672, title: 'Bardot-Inspired Hair Tips', url: '/bardothairstyles' },
  { id: 674, title: 'Retro Hair Volume Techniques', url: '/bardothairstyles' },
  { id: 675, title: 'Textured Bun for 60s Look', url: '/bardothairstyles' },
  { id: 676, title: 'How to Style Bardot Curtain Bangs', url: '/bardothairstyles' },
  { id: 677, title: 'Retro Beauty Influencer', url: '/bardothairstyles' },
  { id: 678, title: 'Hair Tutorials for Voluminous Buns', url: '/bardothairstyles' },
  { id: 679, title: 'Brigitte Bardot Bun Styling', url: '/bardothairstyles' },
  { id: 680, title: 'Timeless 1960s Hair Trends', url: '/bardothairstyles' },
  { id: 681, title: 'Fluffy Curtain Bangs Step-by-Step', url: '/bardothairstyles' },
  { id: 682, title: 'Sabrina Carpenter Hair Inspiration', url: '/bardothairstyles' },
  { id: 683, title: 'Iconic Hairstyles of 60s', url: '/bardothairstyles' },
  { id: 684, title: 'Bardot Glamour Guide', url: '/bardothairstyles' },
  { id: 685, title: 'Elegant Updo Hair Ideas', url: '/bardothairstyles' },
  { id: 686, title: 'Hairstyling with Faux Bangs', url: '/bardothairstyles' },
  { id: 687, title: 'Bardot-Style Bun for Long Hair', url: '/bardothairstyles' },
  { id: 688, title: 'Hair Products for Bardot Look', url: '/bardothairstyles' },
  { id: 689, title: '60s-Inspired Beauty Tips', url: '/bardothairstyles' },
  { id: 691, title: 'Iconic Updo Recreations', url: '/bardothairstyles' },
  { id: 692, title: 'Textured Bun Tips for Retro Glam', url: '/bardothairstyles' },
  { id: 693, title: 'How to Use Bun Makers', url: '/bardothairstyles' },
  { id: 694, title: 'Bardot Bangs Styling Tricks', url: '/bardothairstyles' },
  { id: 695, title: 'Modern Retro Hairstyles', url: '/bardothairstyles' },
  { id: 696, title: '60s Vintage Vibes Hair Tutorials', url: '/bardothairstyles' },
  { id: 697, title: 'Dallas Cowboys Cheerleader Makeup Tutorial', url: '/articles/dcc-cheerleader' },
  { id: 698, title: 'DCC Makeup Look', url: '/articles/dcc-cheerleader' },
  { id: 700, title: 'Cheerleader Glam Tutorial', url: '/articles/dcc-cheerleader' },
  { id: 701, title: 'Smokey Eye Makeup Tutorial', url: '/articles/dcc-cheerleader' },
  { id: 702, title: 'Iconic Cheerleader Makeup', url: '/articles/dcc-cheerleader' },
  { id: 703, title: 'Madeline Cope-Inspired Makeup', url: '/articles/dcc-cheerleader' },
  { id: 704, title: 'Southern Bombshell Beauty', url: '/articles/dcc-cheerleader' },
  { id: 705, title: 'High-End Makeup Products', url: '/articles/dcc-cheerleader' },
  { id: 706, title: 'Affordable Makeup Dupes', url: '/articles/dcc-cheerleader' },
  { id: 707, title: 'Cheer Squad Beauty Trends', url: '/articles/dcc-cheerleader' },
  { id: 708, title: 'Step-by-Step Makeup Guide', url: '/articles/dcc-cheerleader' },
  { id: 710, title: 'Pop Culture Beauty', url: '/articles/dcc-cheerleader' },
  { id: 711, title: 'Dallas Cowboys Cheerleaders Makeup Tips', url: '/articles/dcc-cheerleader' },
  { id: 712, title: 'Bold Smokey Eye Tutorial', url: '/articles/dcc-cheerleader' },
  { id: 713, title: 'Cheerleader Costume Makeup', url: '/articles/dcc-cheerleader' },
  { id: 714, title: 'Halloween Cheerleader Makeup', url: '/articles/dcc-cheerleader' },
  { id: 715, title: 'Flawless Foundation Tutorial', url: '/articles/dcc-cheerleader' },
  { id: 716, title: 'Makeup for Performances', url: '/articles/dcc-cheerleader' },
  { id: 717, title: 'Celebrity Makeup Trends', url: '/articles/dcc-cheerleader' },
  { id: 718, title: 'Dallas Cowboys Glam', url: '/articles/dcc-cheerleader' },
  { id: 719, title: 'Glam Makeup for Special Occasions', url: '/articles/dcc-cheerleader' },
  { id: 720, title: 'Iconic Glam Tutorials', url: '/articles/dcc-cheerleader' },

  { id: 721, title: 'Bridesmaid Boxes', url: '/bridesmaidboxes' },
  { id: 722, title: 'DIY Bridesmaid Gifts', url: '/bridesmaidboxes' },
  { id: 723, title: 'Wedding Proposal Ideas', url: '/bridesmaidboxes' },
  { id: 725, title: 'Bridesmaid Proposal Box Ideas', url: '/bridesmaidboxes' },
  { id: 726, title: 'Budget-Friendly Bridesmaid Gifts', url: '/bridesmaidboxes' },
  { id: 727, title: 'Whimsical Wedding Planning', url: '/bridesmaidboxes' },
  { id: 728, title: 'Unique Bridesmaid Gifts', url: '/bridesmaidboxes' },
  { id: 729, title: 'Personalized Bridesmaid Boxes', url: '/bridesmaidboxes' },
  { id: 730, title: 'Handcrafted Bridal Gifts', url: '/bridesmaidboxes' },
  { id: 731, title: 'Fairytale Bridal DIY', url: '/bridesmaidboxes' },
  { id: 732, title: 'Wedding Party Proposal Ideas', url: '/bridesmaidboxes' },
  { id: 733, title: 'Bridesmaid Proposal Cards', url: '/bridesmaidboxes' },
  { id: 734, title: 'Wedding Gift Ideas', url: '/bridesmaidboxes' },
  { id: 735, title: 'Creative Wedding Planning Tips', url: '/bridesmaidboxes' },
  { id: 736, title: 'Bridesmaid Proposal Boxes How-To', url: '/bridesmaidboxes' },
  { id: 737, title: 'Bridal Inspiration', url: '/bridesmaidboxes' },
  { id: 738, title: 'Wedding Aesthetic Ideas', url: '/bridesmaidboxes' },
  { id: 739, title: 'DIY Bridesmaid Proposal Ideas', url: '/bridesmaidboxes' },
  { id: 740, title: 'Diary Post on Fillers', url: '/diaryfillers' },
  { id: 741, title: 'Injectable Fillers Journey', url: '/diaryfillers' },
  { id: 743, title: 'Self-Acceptance Stories', url: '/diaryfillers' },
  { id: 744, title: 'Beauty Standards Reflection', url: '/diaryfillers' },
  { id: 745, title: 'Non-Surgical Cosmetic Procedures', url: '/diaryfillers' },
  { id: 746, title: 'Self-Esteem Growth', url: '/diaryfillers' },
  { id: 747, title: 'Personal Development', url: '/diaryfillers' },
  { id: 748, title: 'Teenage Beauty Challenges', url: '/diaryfillers' },
  { id: 749, title: 'Cosmetic Enhancements Impact', url: '/diaryfillers' },
  { id: 750, title: 'Confidence Building', url: '/diaryfillers' },
  { id: 751, title: 'Overcoming Insecurities', url: '/diaryfillers' },
  { id: 752, title: 'Reflective Beauty Blog', url: '/diaryfillers' },
  { id: 753, title: 'Self-Image Struggles', url: '/diaryfillers' },
  { id: 754, title: 'Diary Entry Beauty Journey', url: '/diaryfillers' },
  { id: 755, title: 'Young Adult Experiences', url: '/diaryfillers' },
  { id: 756, title: 'Embracing Authenticity', url: '/diaryfillers' },
  { id: 757, title: 'Mental Health and Appearance', url: '/diaryfillers' },
  { id: 759, title: 'Self-Reflection in Beauty', url: '/diaryfillers' },
  { id: 800, title: 'Grace Kelly Makeup', url: '/gracekelly' },
  { id: 801, title: 'Hollywood Glam Beauty', url: '/gracekelly' },
  { id: 802, title: 'Grace Kelly 1950s Style', url: '/gracekelly' },
  { id: 803, title: 'Vintage Makeup Tutorials', url: '/gracekelly' },
  { id: 805, title: 'Old Hollywood Elegance', url: '/gracekelly' },
  { id: 806, title: 'Grace Kelly Beauty Tips', url: '/gracekelly' },
  { id: 807, title: 'Timeless Beauty', url: '/gracekelly' },
  { id: 808, title: 'Red Lip Tutorial', url: '/gracekelly' },
  { id: 809, title: 'Grace Kelly Inspired Look', url: '/gracekelly' },
  { id: 810, title: 'Classic Vintage Style', url: '/gracekelly' },
  { id: 811, title: 'Grace Kelly Fashion', url: '/gracekelly' },
  { id: 812, title: 'Vintage Vibes Tutorials', url: '/gracekelly' },
  { id: 813, title: 'Retro Makeup Guide', url: '/gracekelly' },
  { id: 814, title: 'Grace Kelly Bridal Makeup', url: '/gracekelly' },
  { id: 815, title: 'Iconic Beauty Recreations', url: '/gracekelly' },
  { id: 816, title: 'Grace Kelly Hair Tutorial', url: '/gracekelly' },
  { id: 817, title: 'Grace Kelly Rear Window Dress', url: '/gracekelly' },
  { id: 818, title: 'Red Carpet Makeup', url: '/gracekelly' },
  { id: 819, title: 'Classic Beauty Icons', url: '/gracekelly' },
  { id: 820, title: 'Old Hollywood Glam Tutorials', url: '/gracekelly' },
  { id: 800, title: 'Grace Kelly Makeup', url: '/gracekelly' },
  { id: 801, title: 'Hollywood Glam Beauty', url: '/gracekelly' },
  { id: 802, title: 'Grace Kelly 1950s Style', url: '/gracekelly' },
  { id: 803, title: 'Vintage Makeup Tutorials', url: '/gracekelly' },
  { id: 805, title: 'Old Hollywood Elegance', url: '/gracekelly' },
  { id: 806, title: 'Grace Kelly Beauty Tips', url: '/gracekelly' },
  { id: 807, title: 'Timeless Beauty', url: '/gracekelly' },
  { id: 808, title: 'Red Lip Tutorial', url: '/gracekelly' },
  { id: 809, title: 'Grace Kelly Inspired Look', url: '/gracekelly' },
  { id: 810, title: 'Classic Vintage Style', url: '/gracekelly' },
  { id: 811, title: 'Grace Kelly Fashion', url: '/gracekelly' },
  { id: 812, title: 'Vintage Vibes Tutorials', url: '/gracekelly' },
  { id: 813, title: 'Retro Makeup Guide', url: '/gracekelly' },
  { id: 814, title: 'Grace Kelly Bridal Makeup', url: '/gracekelly' },
  { id: 815, title: 'Iconic Beauty Recreations', url: '/gracekelly' },
  { id: 816, title: 'Grace Kelly Hair Tutorial', url: '/gracekelly' },
  { id: 817, title: 'Grace Kelly Rear Window Dress', url: '/gracekelly' },
  { id: 818, title: 'Red Carpet Makeup', url: '/gracekelly' },
  { id: 819, title: 'Classic Beauty Icons', url: '/gracekelly' },
  { id: 820, title: 'Old Hollywood Glam Tutorials', url: '/gracekelly' },

  { id: 822, title: 'Sharon Tate Makeup', url: '/sharontatebridal' },
  { id: 823, title: 'Iconic 60s Beauty', url: '/sharontatebridal' },
  { id: 825, title: 'Retro Beauty Inspiration', url: '/sharontatebridal' },
  { id: 826, title: '60s Cut Crease Tutorial', url: '/sharontatebridal' },
  { id: 827, title: 'Vintage Makeup Tips', url: '/sharontatebridal' },
  { id: 828, title: 'Bohemian Chic Style', url: '/sharontatebridal' },
  { id: 829, title: 'Bold Eyeliner Tutorial', url: '/sharontatebridal' },
  { id: 830, title: 'Timeless Beauty', url: '/sharontatebridal' },
  { id: 831, title: 'Sharon Tate Look Recreation', url: '/sharontatebridal' },
  { id: 832, title: 'California Glow Makeup', url: '/sharontatebridal' },
  { id: 833, title: 'Bronzed Skin Tips', url: '/sharontatebridal' },
  { id: 834, title: 'Natural Brows', url: '/sharontatebridal' },
  { id: 835, title: 'Radiant Foundation Guide', url: '/sharontatebridal' },
  { id: 836, title: 'Nude Lipstick Tutorial', url: '/sharontatebridal' },
  { id: 837, title: 'Twiggy-Inspired Lashes', url: '/sharontatebridal' },
  { id: 838, title: 'Sharon Tate Bridal Makeup', url: '/sharontatebridal' },
  { id: 839, title: 'Hollywood Retro Glamour', url: '/sharontatebridal' },
  { id: 840, title: 'Beauty Influencer Guides', url: '/sharontatebridal' },
  { id: 841, title: 'Classic Beauty Icons', url: '/sharontatebridal' },
  { id: 842, title: 'Vintage Beauty Essentials', url: '/sharontatebridal' },
  { id: 843, title: 'Sharon Tate Inspired Products', url: '/sharontatebridal' },
  { id: 844, title: '60s Fashion and Makeup', url: '/sharontatebridal' },
  { id: 846, title: 'Sharon Tate Bohemian Style', url: '/sharontatebridal' },
  { id: 847, title: 'Recreating Sharon Tate Looks', url: '/sharontatebridal' },
  { id: 848, title: 'Makeup Artistry Tips', url: '/sharontatebridal' },
  { id: 849, title: 'Retro Chic Beauty', url: '/sharontatebridal' },
  { id: 850, title: 'Sophia Loren', url: '/sophialoren' },
  { id: 851, title: 'Arabesque Makeup Tutorial', url: '/sophialoren' },
  { id: 852, title: '60s Vintage Makeup', url: '/sophialoren' },
  { id: 853, title: 'Italian Beauty', url: '/sophialoren' },
  { id: 855, title: 'Retro Glamour', url: '/sophialoren' },
  { id: 856, title: 'Sophia Loren Iconic Look', url: '/sophialoren' },
  { id: 857, title: 'Sophia Loren Eyeliner', url: '/sophialoren' },
  { id: 858, title: 'Bold Cat-Eye Tutorial', url: '/sophialoren' },
  { id: 859, title: 'Sophia Loren Makeup Secrets', url: '/sophialoren' },
  { id: 860, title: 'Italian Glamour Makeup', url: '/sophialoren' },
  { id: 861, title: 'Vintage Hollywood Beauty', url: '/sophialoren' },
  { id: 862, title: 'Classic Makeup Techniques', url: '/sophialoren' },
  { id: 863, title: 'Sophia Loren Hair Tutorial', url: '/sophialoren' },
  { id: 864, title: '1960s Makeup Guide', url: '/sophialoren' },
  { id: 865, title: 'Sophia Loren Dramatic Lashes', url: '/sophialoren' },
  { id: 866, title: 'Old Hollywood Makeup', url: '/sophialoren' },
  { id: 867, title: 'Timeless Beauty Icons', url: '/sophialoren' },
  { id: 868, title: 'Sophia Loren Arabesque Style', url: '/sophialoren' },
  { id: 869, title: 'Beauty Influencer Tutorials', url: '/sophialoren' },
  { id: 871, title: 'Retro Chic Makeup', url: '/sophialoren' },
  { id: 872, title: 'Sophia Loren Signature Look', url: '/sophialoren' },
  { id: 873, title: 'Makeup for Olive Skin', url: '/sophialoren' },
  { id: 874, title: 'Bold Eyeliner', url: '/sophialoren' },
  { id: 875, title: 'Sophia Loren Smokey Eye', url: '/sophialoren' },
  { id: 876, title: 'Sophia Loren Nude Lips', url: '/sophialoren' },
  { id: 877, title: 'Retro Hair and Makeup', url: '/sophialoren' },
  { id: 878, title: 'Glamorous Italian Style', url: '/sophialoren' },
  { id: 879, title: 'Sophia Loren Transformation', url: '/sophialoren' },
  { id: 880, title: 'Hollywood Beauty Icons', url: '/sophialoren' },
  { id: 881, title: 'Sophia Loren Inspired Products', url: '/sophialoren' },
  { id: 882, title: 'Vintage Beauty Tips', url: '/sophialoren' },
  { id: 883, title: 'Sophia Loren Time Travel Tutorial', url: '/sophialoren' },
  { id: 884, title: 'Twiggy', url: '/twiggy' },
  { id: 885, title: '60s MOD Makeup', url: '/twiggy' },
  { id: 887, title: 'Twiggy Makeup Tutorial', url: '/twiggy' },
  { id: 888, title: 'Blue Eyeshadow', url: '/twiggy' },
  { id: 889, title: 'Vintage Beauty', url: '/twiggy' },
  { id: 890, title: 'Time Travel Tutorials', url: '/twiggy' },
  { id: 891, title: "Twiggy's Iconic Look", url: '/twiggy' },
  { id: 892, title: '1960s Fashion', url: '/twiggy' },
  { id: 893, title: 'MOD Style', url: '/twiggy' },
  { id: 894, title: 'Retro Makeup Tutorial', url: '/twiggy' },
  { id: 895, title: 'Twiggy Lashes', url: '/twiggy' },
  { id: 896, title: 'Bold Eyeliner', url: '/twiggy' },
  { id: 897, title: 'Drawn-On Lashes', url: '/twiggy' },
  { id: 898, title: 'Twiggy Hair', url: '/twiggy' },
  { id: 899, title: 'Pastel Blue Eyeshadow', url: '/twiggy' },
  { id: 900, title: 'Twiggy Pale Lips', url: '/twiggy' },
  { id: 901, title: 'Vintage-Inspired Beauty', url: '/twiggy' },
  { id: 902, title: 'Twiggy Freckles', url: '/twiggy' },
  { id: 903, title: 'Twiggy Spring Hairstyle', url: '/twiggy' },
  { id: 904, title: 'Anna Sui Cosmetics', url: '/twiggy' },
  { id: 905, title: 'Makeup for Vintage Vibes', url: '/twiggy' },
  { id: 906, title: 'Retro Beauty Tips', url: '/twiggy' },
  { id: 907, title: '60s Makeup Breakdown', url: '/twiggy' },
  { id: 908, title: "Twiggy's Youthful Glow", url: '/twiggy' },
  { id: 909, title: 'Modern Vintage Makeup', url: '/twiggy' },
  { id: 910, title: 'Twiggy-Inspired Products', url: '/twiggy' },
  { id: 911, title: 'Twiggy Beauty Secrets', url: '/twiggy' },
  { id: 912, title: '60s Iconic Style', url: '/twiggy' },
  { id: 913, title: 'Twiggy Curls with Flowers', url: '/twiggy' },
  { id: 914, title: 'Vintage Vibes Merchandise', url: '/twiggy' },
  { id: 916, title: 'Ariana Grande Wicked Makeup Tutorial', url: '/articles/wicked' },
  { id: 917, title: 'Glinda Makeup Tips', url: '/articles/wicked' },
  { id: 919, title: 'Wicked the Movie Makeup 2024', url: '/articles/wicked' },
  { id: 920, title: 'Ariana Grande Glinda Pink Glam', url: '/articles/wicked' },
  { id: 921, title: 'Wicked-Inspired Halloween Makeup', url: '/articles/wicked' },
  { id: 922, title: 'Step-by-Step Glinda Makeup', url: '/articles/wicked' },
  { id: 923, title: 'Pop Culture Beauty Tutorials', url: '/articles/wicked' },
  { id: 924, title: 'Wicked Broadway Beauty Looks', url: '/articles/wicked' },
  { id: 925, title: 'IT Cosmetics Wicked Brushes', url: '/articles/wicked' },
  { id: 926, title: 'One/Size Wicked Collection', url: '/articles/wicked' },
  { id: 927, title: 'Glinda-Inspired Makeup Products', url: '/articles/wicked' },
  { id: 928, title: 'Transforming into Glinda Makeup', url: '/articles/wicked' },
  { id: 929, title: 'Glinda Costume Ideas', url: '/articles/wicked' },
  { id: 930, title: 'REM Beauty Wicked Collaboration', url: '/articles/wicked' },
  { id: 931, title: 'Pink Glam Makeup Tutorial', url: '/articles/wicked' },
  { id: 932, title: 'Ariana Grande Transformation Makeup', url: '/articles/wicked' },
  { id: 933, title: 'Wicked Musical-Inspired Beauty', url: '/articles/wicked' },
  { id: 934, title: 'Wicked Halloween Costumes', url: '/articles/wicked' },
  { id: 935, title: 'Iconic Wicked Looks', url: '/articles/wicked' },
  { id: 956, title: 'Jean Shrimpton Yardley Makeup', url: '/yardley1965' },
  { id: 957, title: 'Jean Shrimpton Tutorial', url: '/yardley1965' },
  { id: 958, title: '1960s Makeup Look', url: '/yardley1965' },
  { id: 959, title: 'Vintage Yardley Ad Recreation', url: '/yardley1965' },
  { id: 961, title: 'Vintage Beauty', url: '/yardley1965' },
  { id: 962, title: 'Yardley Oh De London Ad', url: '/yardley1965' },
  { id: 963, title: 'Jean Shrimpton Yardley 1965', url: '/yardley1965' },
  { id: 964, title: 'MOD Style Makeup', url: '/yardley1965' },
  { id: 965, title: 'Pop Art Makeup Look', url: '/yardley1965' },
  { id: 966, title: '60s Supermodel Makeup', url: '/yardley1965' },
  { id: 967, title: 'Retro Beauty Tutorials', url: '/yardley1965' },
  { id: 968, title: 'Yardley Fragrance Makeup Inspiration', url: '/yardley1965' },
  { id: 969, title: 'Iconic 60s Makeup Recreations', url: '/yardley1965' },
  { id: 970, title: 'Vintage Vibes', url: '/yardley1965' },
  { id: 971, title: 'Retro Makeup Ideas', url: '/yardley1965' },
  { id: 972, title: 'Time Travel Beauty Tutorials', url: '/yardley1965' },
  { id: 973, title: '1960s Supermodel Beauty', url: '/yardley1965' },
  { id: 974, title: 'Yardley Vintage Makeup Collection', url: '/yardley1965' },
  { id: 976, title: 'Andaz Maui', url: '/andazmaui' },
  { id: 977, title: 'Maui Resort', url: '/andazmaui' },
  { id: 978, title: 'Luxury Hotel', url: '/andazmaui' },
  { id: 979, title: 'Hotel Review', url: '/andazmaui' },
  { id: 980, title: 'Wailea Resort', url: '/andazmaui' },
  { id: 981, title: 'Travel Blog', url: '/andazmaui' },
  { id: 982, title: 'Maui Vacation', url: '/andazmaui' },
  { id: 983, title: 'Luxury Resort', url: '/andazmaui' },
  { id: 984, title: 'Oceanfront Resort', url: '/andazmaui' },
  { id: 985, title: 'Hawaii Vacation', url: '/andazmaui' },
  { id: 986, title: 'Best Maui Hotels', url: '/andazmaui' },
  { id: 987, title: 'Andaz Maui Review', url: '/andazmaui' },
  { id: 988, title: 'Luxury Travel', url: '/andazmaui' },
  { id: 989, title: 'Beachfront Resort', url: '/andazmaui' },
  { id: 990, title: 'Hawaiian Luxury Resort', url: '/andazmaui' },
  { id: 991, title: 'Top Resorts in Maui', url: '/andazmaui' },
  { id: 992, title: 'Wailea Beach Hotels', url: '/andazmaui' },
  { id: 993, title: 'Morimoto Maui', url: '/andazmaui' },
  { id: 994, title: 'Hawaiian Luaus', url: '/andazmaui' },
  { id: 995, title: 'Infinity Pools', url: '/andazmaui' },
  { id: 996, title: 'Tropical Getaway', url: '/andazmaui' },
  { id: 997, title: 'Resort Amenities', url: '/andazmaui' },
  { id: 998, title: 'Spa', url: '/andazmaui' },
  { id: 999, title: 'Fitness Center', url: '/andazmaui' },
  { id: 1000, title: 'Golf Courses', url: '/andazmaui' },
  { id: 1001, title: 'Dining Options', url: '/andazmaui' },
  { id: 1001, title: 'Brussels Travel Guide', url: '/paris-brussels-travel-guide' },
  { id: 1002, title: 'Things to Do in Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1003, title: 'Belgian Chocolate Shops', url: '/paris-brussels-travel-guide' },
  { id: 1004, title: 'Galeries Royales Saint-Hubert', url: '/paris-brussels-travel-guide' },
  { id: 1005, title: 'Best Waffles in Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1006, title: 'Travel Tips', url: '/paris-brussels-travel-guide' },
  { id: 1008, title: 'Brussels Hidden Gems', url: '/paris-brussels-travel-guide' },
  { id: 1009, title: 'Brussels Day Trips', url: '/paris-brussels-travel-guide' },
  { id: 1010, title: 'Paris by Train', url: '/paris-brussels-travel-guide' },
  { id: 1011, title: 'Travel from Belgium to France', url: '/paris-brussels-travel-guide' },
  { id: 1012, title: 'Best Restaurants in Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1013, title: 'Must-See Places Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1014, title: 'Sainte Catherine Church', url: '/paris-brussels-travel-guide' },
  { id: 1015, title: 'Grand-Place Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1016, title: 'Manneken Pis', url: '/paris-brussels-travel-guide' },
  { id: 1017, title: 'Place du Petit Sablon', url: '/paris-brussels-travel-guide' },
  { id: 1018, title: 'International School of Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1019, title: 'ISB Alumni', url: '/paris-brussels-travel-guide' },
  { id: 1020, title: 'Belgian Travel', url: '/paris-brussels-travel-guide' },
  { id: 1021, title: 'Vintage Shopping Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1022, title: 'Luxury Hotels Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1023, title: 'Mary’s Chocolate Shop', url: '/paris-brussels-travel-guide' },
  { id: 1024, title: 'Best Seafood Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1025, title: 'Brussels Public Transport', url: '/paris-brussels-travel-guide' },
  { id: 1026, title: 'European City Breaks', url: '/paris-brussels-travel-guide' },
  { id: 1027, title: 'Romantic Day Trips', url: '/paris-brussels-travel-guide' },
  { id: 1028, title: 'Paris Olympics 2024', url: '/paris-brussels-travel-guide' },
  { id: 1029, title: 'Exploring Belgium', url: '/paris-brussels-travel-guide' },
  { id: 1030, title: 'Travel Inspiration', url: '/paris-brussels-travel-guide' },
  { id: 1031, title: 'Luxury Travel', url: '/paris-brussels-travel-guide' },
  { id: 1032, title: 'Nostalgic Travel', url: '/paris-brussels-travel-guide' },
  { id: 1033, title: 'Sentimental Travel', url: '/paris-brussels-travel-guide' },
  { id: 1034, title: 'Best Cafes in Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1035, title: 'Top Tourist Attractions Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1036, title: 'Local Tips Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1037, title: 'Sorrento Souvenirs', url: '/paris-brussels-travel-guide' },
  { id: 1038, title: 'European Travel Blogger', url: '/paris-brussels-travel-guide' },
  { id: 1039, title: 'Exploring Europe by Train', url: '/paris-brussels-travel-guide' },
  { id: 1040, title: 'Day Trip to Paris', url: '/paris-brussels-travel-guide' },
  { id: 1041, title: 'Luxury Shopping Paris', url: '/paris-brussels-travel-guide' },
  { id: 1042, title: 'Belgium Travel Tips', url: '/paris-brussels-travel-guide' },
  { id: 1043, title: 'Train Travel Europe', url: '/paris-brussels-travel-guide' },
  { id: 1044, title: 'Unique Travel Experiences', url: '/paris-brussels-travel-guide' },
  { id: 1045, title: 'Girly Travel Guide', url: '/paris-brussels-travel-guide' },
  { id: 1046, title: 'Romantic Travel', url: '/paris-brussels-travel-guide' },
  { id: 1047, title: 'Historical Travel Destinations', url: '/paris-brussels-travel-guide' },
  { id: 1048, title: 'Cute Cafes Paris', url: '/paris-brussels-travel-guide' },
  { id: 1049, title: 'Belgian Beer', url: '/paris-brussels-travel-guide' },
  { id: 1050, title: 'Classic Brasserie Style', url: '/paris-brussels-travel-guide' },
  { id: 1051, title: 'Flemish Cuisine', url: '/paris-brussels-travel-guide' },
  { id: 1053, title: 'Iconic European Landmarks', url: '/paris-brussels-travel-guide' },
  { id: 1054, title: 'Best Squares in Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1055, title: 'Chocolate Tasting Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1056, title: 'Travel Photography Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1057, title: 'Fashion Shopping Paris', url: '/paris-brussels-travel-guide' },
  { id: 1058, title: 'Vintage Vibes Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1060, title: 'European Adventures', url: '/paris-brussels-travel-guide' },
  { id: 1061, title: 'Foodie Travel Guide', url: '/paris-brussels-travel-guide' },
  { id: 1062, title: 'Brussels Architecture', url: '/paris-brussels-travel-guide' },
  { id: 1063, title: 'Luxury European Hotels', url: '/paris-brussels-travel-guide' },
  { id: 1064, title: 'Sightseeing Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1065, title: 'Top European Destinations', url: '/paris-brussels-travel-guide' },
  { id: 1066, title: 'French-Belgian Cuisine', url: '/paris-brussels-travel-guide' },
  { id: 1067, title: 'Best Brunch in Brussels', url: '/paris-brussels-travel-guide' },
  { id: 1068, title: 'Beautiful Squares Europe', url: '/paris-brussels-travel-guide' },
  { id: 1069, title: 'European Boutique Hotels', url: '/paris-brussels-travel-guide' },
  { id: 1070, title: 'Walt Disney World Beach Club Resort Review', url: '/disneybeachclubresort' },
  { id: 1071, title: 'Disney Deluxe Resorts Review', url: '/disneybeachclubresort' },
  { id: 1072, title: 'Family-Friendly Disney Resorts 2024', url: '/disneybeachclubresort' },
  { id: 1073, title: 'Best Disney Resorts for Families', url: '/disneybeachclubresort' },
  { id: 1074, title: 'Disney World Luxury Accommodations', url: '/disneybeachclubresort' },
  { id: 1075, title: 'Epcot Area Resorts', url: '/disneybeachclubresort' },
  { id: 1076, title: 'Disney Hotel Reviews and Ratings', url: '/disneybeachclubresort' },
  { id: 1077, title: 'Beaches & Cream Soda Shop Highlights', url: '/disneybeachclubresort' },
  { id: 1078, title: 'Storm-Along Bay Pool Review', url: '/disneybeachclubresort' },
  { id: 1079, title: 'Disney Yacht Club Comparison', url: '/disneybeachclubresort' },
  { id: 1080, title: 'Disney World Transportation Tips', url: '/disneybeachclubresort' },
  { id: 1081, title: 'Accessible Disney Resorts', url: '/disneybeachclubresort' },
  { id: 1082, title: 'Disney World New England-Style Resorts', url: '/disneybeachclubresort' },
  { id: 1083, title: 'Best Dining Options at Disney Resorts', url: '/disneybeachclubresort' },
  { id: 1084, title: 'Planning a Disney World Vacation 2024', url: '/disneybeachclubresort' },
  { id: 1085, title: 'Disney World Hidden Gems', url: '/disneybeachclubresort' },
  { id: 1086, title: 'Top Disney World Amenities', url: '/disneybeachclubresort' },
  { id: 1087, title: 'Beach Club vs Yacht Club Resorts', url: '/disneybeachclubresort' },
  { id: 1088, title: 'Nostalgic Disney Vacations', url: '/disneybeachclubresort' },
  { id: 1089, title: 'Disney Resort Suites and Rooms', url: '/disneybeachclubresort' },
  { id: 1090, title: 'Disney BoardWalk Attractions', url: '/disneybeachclubresort' },
  { id: 1091, title: 'Adult-Friendly Disney Resorts', url: '/disneybeachclubresort' },
  { id: 1092, title: 'Disney World Romantic Getaways', url: '/disneybeachclubresort' },
  { id: 1093, title: 'Disney Resort Accessibility Features', url: '/disneybeachclubresort' },
  { id: 1094, title: 'Best Disney World Pools and Water Parks', url: '/disneybeachclubresort' },
  { id: 1095, title: 'Disney Dining Recommendations', url: '/disneybeachclubresort' },
  { id: 1096, title: 'Disney World Nautical-Themed Hotels', url: '/disneybeachclubresort' },
  { id: 1097, title: 'Travel Tips for Disney World Resorts', url: '/disneybeachclubresort' },
  { id: 1098, title: 'Disney World Budget-Friendly Tips', url: '/disneybeachclubresort' },
  { id: 1099, title: 'Disney Resort Proximity to Epcot and Hollywood Studios', url: '/disneybeachclubresort' },
  { id: 1100, title: 'Beaches & Cream Soda Shop Dessert Reviews', url: '/disneybeachclubresort' },
  { id: 1101, title: 'Disney BoardWalk Entertainment', url: '/disneybeachclubresort' },
  { id: 1102, title: 'Walt Disney World Epcot Guide', url: '/epcot' },
  { id: 1103, title: 'Best Disney Park Itineraries 2024', url: '/epcot' },
  { id: 1104, title: 'Epcot Highlights and Tips', url: '/epcot' },
  { id: 1105, title: 'Magic Kingdom Travel Guide', url: '/epcot' },
  { id: 1106, title: 'Animal Kingdom Experiences', url: '/epcot' },
  { id: 1107, title: 'Disney World Dining Recommendations', url: '/epcot' },
  { id: 1108, title: 'Disney World for Adults', url: '/epcot' },
  { id: 1109, title: 'Disney Trip Planning Ideas', url: '/epcot' },
  { id: 1110, title: 'Disney Genie+ Tips and Tricks', url: '/epcot' },
  { id: 1111, title: 'Best Disney Rides and Attractions', url: '/epcot' },
  { id: 1112, title: 'EPCOT World Showcase Pavilions', url: '/epcot' },
  { id: 1113, title: 'Pandora - World of Avatar Attractions', url: '/epcot' },
  { id: 1114, title: 'Frozen Ever After Ride Review', url: '/epcot' },
  { id: 1115, title: 'Expedition Everest Roller Coaster', url: '/epcot' },
  { id: 1116, title: 'Disney Park Hopping Advice', url: '/epcot' },
  { id: 1117, title: 'Epcot Flower & Garden Festival Guide', url: '/epcot' },
  { id: 1118, title: 'Disney Nighttime Shows and Fireworks', url: '/epcot' },
  { id: 1119, title: 'Family-Friendly Disney Travel', url: '/epcot' },
  { id: 1120, title: 'Disney Luxury Experiences', url: '/epcot' },
  { id: 1121, title: 'Disney Park Photography Tips', url: '/epcot' },
  { id: 1122, title: 'Animal Kingdom Safari Review', url: '/epcot' },
  { id: 1123, title: 'Rainforest Café at Disney World', url: '/epcot' },
  { id: 1124, title: 'Disney Park Souvenirs and Shopping', url: '/epcot' },
  { id: 1125, title: 'Disney Food Trucks Recommendations', url: '/epcot' },
  { id: 1126, title: 'Top Disney Parks for Couples', url: '/epcot' },
  { id: 1127, title: 'Disney Theme Park Seasonal Events', url: '/epcot' },
  { id: 1128, title: 'Disney Vlogger Travel Diary', url: '/epcot' },
  { id: 1129, title: 'DIY Bridal Flower Pins Tutorial', url: '/diy-flowers' },
  { id: 1130, title: 'Mamma Mia Inspired Wedding Accessories', url: '/diy-flowers' },
  { id: 1132, title: 'Romantic Bridal Flower Crown DIY', url: '/diy-flowers' },
  { id: 1133, title: 'How to Make Wedding Flower Pins', url: '/diy-flowers' },
  { id: 1134, title: 'Boho Wedding Accessories Guide', url: '/diy-flowers' },
  { id: 1135, title: 'Sophie Sheridan Wedding Inspiration', url: '/diy-flowers' },
  { id: 1136, title: 'Flower Comb Tutorial for Brides', url: '/diy-flowers' },
  { id: 1137, title: 'Destination Wedding Hair Accessories', url: '/diy-flowers' },
  { id: 1138, title: 'Whimsical Bridal Style Ideas', url: '/diy-flowers' },
  { id: 1139, title: 'Greek Island-Inspired Wedding Crafts', url: '/diy-flowers' },
  { id: 1140, title: 'Champagne Flower Crown Tutorial', url: '/diy-flowers' },
  { id: 1141, title: 'Floral Hair Pins for Bridesmaids', url: '/diy-flowers' },
  { id: 1142, title: 'Bridal Accessories on a Budget', url: '/diy-flowers' },
  { id: 1143, title: 'Mamma Mia Wedding Flower Crafts', url: '/diy-flowers' },
  { id: 1144, title: 'Cinematic Bridal Looks Inspired by Sophie', url: '/diy-flowers' },
  { id: 1145, title: 'Easy DIY Wedding Crafts for Brides', url: '/diy-flowers' },
  { id: 1146, title: 'Burlap and Lace Bridal Flower Pins', url: '/diy-flowers' },
  { id: 1147, title: 'Elegant Flower Crown Veil DIY', url: '/diy-flowers' },
  { id: 1149, title: 'How to Create Backlit Wedding Flower Crowns', url: '/diy-flowers' },
  { id: 1150, title: 'Fable Auckland', url: '/fable-auckland' },
  { id: 1151, title: 'Luxury Hotel', url: '/fable-auckland' },
  { id: 1152, title: 'Auckland Travel', url: '/fable-auckland' },
  { id: 1154, title: 'Hotel Review', url: '/fable-auckland' },
  { id: 1155, title: 'New Zealand', url: '/fable-auckland' },
  { id: 1157, title: 'Fairytale Wedding Ideas', url: '/fairytalewedding' },
  { id: 1158, title: 'Sorrento Wedding Venues', url: '/fairytalewedding' },
  { id: 1159, title: 'Intimate Destination Weddings', url: '/fairytalewedding' },
  { id: 1160, title: 'Whimsical Wedding Planning', url: '/fairytalewedding' },
  { id: 1161, title: 'Italian Villa Wedding Decor', url: '/fairytalewedding' },
  { id: 1162, title: 'Bridal Beauty Tips', url: '/fairytalewedding' },
  { id: 1163, title: 'Wedding Makeup Inspirations', url: '/fairytalewedding' },
  { id: 1164, title: 'Custom Wedding Dress Inspiration', url: '/fairytalewedding' },
  { id: 1165, title: 'Verander West Bridal Gown', url: '/fairytalewedding' },
  { id: 1166, title: 'Romantic Wedding Ceremonies', url: '/fairytalewedding' },
  { id: 1167, title: 'Floral DIY for Weddings', url: '/fairytalewedding' },
  { id: 1168, title: 'Fairycore Bridesmaids Ideas', url: '/fairytalewedding' },
  { id: 1169, title: 'Luxury Italian Weddings', url: '/fairytalewedding' },
  { id: 1170, title: 'Eco-Friendly Wedding Favors', url: '/fairytalewedding' },
  { id: 1171, title: 'Pressed Floral Wedding Keepsakes', url: '/fairytalewedding' },
  { id: 1172, title: 'Wedding Photo Albums', url: '/fairytalewedding' },
  { id: 1173, title: 'Wedding Photography in Sorrento', url: '/fairytalewedding' },
  { id: 1174, title: 'Manolo Blahnik Wedding Shoes', url: '/fairytalewedding' },
  { id: 1175, title: 'Alex Monroe Jewelry Wedding', url: '/fairytalewedding' },
  { id: 1176, title: 'Timeless Wedding Aesthetics', url: '/fairytalewedding' },
  { id: 1177, title: 'Wedding Planning Tips', url: '/fairytalewedding' },
  { id: 1178, title: 'Non-Traditional Weddings', url: '/fairytalewedding' },
  { id: 1179, title: 'DIY Bridal Accessories', url: '/fairytalewedding' },
  { id: 1180, title: 'Wedding Reflections Blog', url: '/fairytalewedding' },
  { id: 1181, title: 'Wedding Video Ideas', url: '/fairytalewedding' },
  { id: 1182, title: 'Wedding Content Creator', url: '/fairytalewedding' },
  { id: 1274, title: 'Ultimate Sorrento Souvenir Guide', url: '/sorrento' },
  { id: 1275, title: 'Best Souvenirs from Sorrento Italy', url: '/sorrento' },
  { id: 1276, title: 'Lemon-Themed Sorrento Souvenirs', url: '/sorrento' },
  { id: 1277, title: 'Sorrento Travel Shopping Tips', url: '/sorrento' },
  { id: 1279, title: 'Leather Goods Sorrento', url: '/sorrento' },
  { id: 1280, title: 'Music Boxes Italy', url: '/sorrento' },
  { id: 1281, title: 'Ceramics and Majolica Sorrento', url: '/sorrento' },
  { id: 1282, title: 'Positano Shopping Guide', url: '/sorrento' },
  { id: 1283, title: 'Capri Bell Charms', url: '/sorrento' },
  { id: 1284, title: 'Limoncello Gifts', url: '/sorrento' },
  { id: 1285, title: 'Sorrento Olive Oil Souvenirs', url: '/sorrento' },
  { id: 1286, title: 'Handmade Ballerina Flats Sorrento', url: '/sorrento' },
  { id: 1287, title: 'Sorrento Foodie Gifts', url: '/sorrento' },
  { id: 1288, title: 'Sorrento Artistic Heritage', url: '/sorrento' },
  { id: 1289, title: 'Italy Travel Blog Souvenirs', url: '/sorrento' },
  { id: 1290, title: 'Romantic Shopping in Italy', url: '/sorrento' },
  { id: 1291, title: 'Iconic Sorrento Keepsakes', url: '/sorrento' },
  { id: 1292, title: 'Amalfi Coast Souvenir Ideas', url: '/sorrento' },
  { id: 1293, title: 'Affordable Souvenirs from Italy', url: '/sorrento' },
  { id: 1294, title: 'Sorrento Lemon-Themed Gifts', url: '/sorrento' },
  { id: 1295, title: 'Italian Gourmet Souvenirs', url: '/sorrento' },
  { id: 1296, title: 'Sorrento Wedding Inspiration', url: '/sorrento' },
  { id: 1297, title: 'Sorrento Travel Tips', url: '/sorrento' },
  { id: 1298, title: 'Personalized Keepsakes from Italy', url: '/sorrento' },
  { id: 1299, title: 'Best Places to Shop in Sorrento', url: '/sorrento' },
  { id: 1301, title: 'What Animal Pretty Are You?', url: '/catpretty' },
  { id: 1302, title: 'Cat Pretty', url: '/catpretty' },
  { id: 1303, title: 'Makeup Tutorial', url: '/catpretty' },
  { id: 1304, title: 'Beauty Guide', url: '/catpretty' },
  { id: 1305, title: 'Bold Makeup', url: '/catpretty' },
  { id: 1306, title: 'Dramatic Style', url: '/catpretty' },
  { id: 1307, title: 'Feline Beauty', url: '/catpretty' },
  { id: 1308, title: 'Makeup Tips', url: '/catpretty' },
  { id: 1309, title: 'Animal-Inspired Beauty', url: '/catpretty' },
  { id: 1310, title: 'Cat-Eye Makeup', url: '/catpretty' },
  { id: 1311, title: 'Smoky Eye', url: '/catpretty' },
  { id: 1312, title: 'Feline Style', url: '/catpretty' },
  { id: 1313, title: 'Bold Eyeliner', url: '/catpretty' },
  { id: 1314, title: 'Victoria Beckham Beauty', url: '/catpretty' },
  { id: 1315, title: 'Makeup by Mario', url: '/catpretty' },
  { id: 1316, title: 'Statement Lashes', url: '/catpretty' },
  { id: 1317, title: 'ISOPIA Lashes', url: '/catpretty' },
  { id: 1318, title: 'Contouring', url: '/catpretty' },
  { id: 1319, title: 'Chic Makeup', url: '/catpretty' },
  { id: 1320, title: 'Celebrity Makeup Looks', url: '/catpretty' },
  { id: 1321, title: 'Bold Lip', url: '/catpretty' },
  { id: 1322, title: 'Sultry Makeup', url: '/catpretty' },
  { id: 1323, title: 'Confident Beauty', url: '/catpretty' },
  { id: 1324, title: 'Fierce Makeup Look', url: '/catpretty' },
  { id: 1325, title: 'Bella Hadid', url: '/catpretty' },
  { id: 1326, title: 'Megan Fox', url: '/catpretty' },
  { id: 1327, title: 'Rihanna', url: '/catpretty' },
  { id: 1328, title: 'Zoe Kravitz', url: '/catpretty' },
  { id: 1329, title: 'Feline-Inspired Fashion', url: '/catpretty' },
  { id: 1330, title: 'Bold Fashion Tips', url: '/catpretty' },
  { id: 1331, title: 'Statement Fashion Pieces', url: '/catpretty' },
  { id: 1332, title: 'Structured Outfits', url: '/catpretty' },
  { id: 1333, title: "'90s Fashion", url: '/catpretty' },
  { id: 1334, title: 'Off-Duty Supermodel', url: '/catpretty' },
  { id: 1335, title: 'Thrift Store Finds', url: '/catpretty' },
  { id: 1336, title: 'Revolve', url: '/catpretty' },
  { id: 1337, title: 'What Animal Pretty Are You? Beauty Tutorials', url: '/catpretty' },
  { id: 1338, title: 'Influencer Makeup Guide', url: '/catpretty' },
  { id: 1339, title: 'Confident Style', url: '/catpretty' },
  { id: 1340, title: 'Animal Beauty Looks', url: '/catpretty' },
  { id: 1341, title: 'Striking Fashion Inspiration', url: '/catpretty' },
  { id: 1382, title: 'Deer Pretty', url: '/deerpretty' },
  { id: 1383, title: 'Makeup Tutorial', url: '/deerpretty' },
  { id: 1384, title: 'Beauty Guide', url: '/deerpretty' },
  { id: 1385, title: 'Ethereal Style', url: '/deerpretty' },
  { id: 1386, title: 'Natural Beauty', url: '/deerpretty' },
  { id: 1387, title: 'Makeup Tips', url: '/deerpretty' },
  { id: 1388, title: 'Animal-Inspired Beauty', url: '/deerpretty' },
  { id: 1389, title: 'Doe-Eyed Makeup', url: '/deerpretty' },
  { id: 1390, title: 'Soft Makeup Look', url: '/deerpretty' },
  { id: 1391, title: 'Gentle Beauty', url: '/deerpretty' },
  { id: 1392, title: 'Serene Style', url: '/deerpretty' },
  { id: 1393, title: 'Makeup by Mario', url: '/deerpretty' },
  { id: 1394, title: 'ISOPIA Lashes', url: '/deerpretty' },
  { id: 1395, title: 'Peachy Blush', url: '/deerpretty' },
  { id: 1396, title: 'Neutral Eyeshadow', url: '/deerpretty' },
  { id: 1397, title: 'Graceful Style', url: '/deerpretty' },
  { id: 1398, title: 'Soft Elegance', url: '/deerpretty' },
  { id: 1399, title: 'Pastel Outfits', url: '/deerpretty' },
  { id: 1400, title: 'Lace Fashion', url: '/deerpretty' },
  { id: 1401, title: 'Feminine Style', url: '/deerpretty' },
  { id: 1402, title: 'Free People', url: '/deerpretty' },
  { id: 1403, title: 'Soft Color Palettes', url: '/deerpretty' },
  { id: 1404, title: 'Gentle Makeup', url: '/deerpretty' },
  { id: 1405, title: 'Fawn Inspiration', url: '/deerpretty' },
  { id: 1406, title: 'Celebrity Style', url: '/deerpretty' },
  { id: 1407, title: 'Jessica Alba Makeup', url: '/deerpretty' },
  { id: 1408, title: 'Leighton Meester Look', url: '/deerpretty' },
  { id: 1409, title: 'Tessa Thompson Beauty', url: '/deerpretty' },
  { id: 1410, title: 'Emmy Rossum Style', url: '/deerpretty' },
  { id: 1411, title: 'Charithra Chandran', url: '/deerpretty' },
  { id: 1412, title: 'Makeup Ideas', url: '/deerpretty' },
  { id: 1413, title: 'Deer-Inspired Looks', url: '/deerpretty' },
  { id: 1414, title: 'Natural Aesthetic', url: '/deerpretty' },
  { id: 1415, title: 'Understated Makeup', url: '/deerpretty' },
  { id: 1416, title: 'Beauty Influencer Tutorials', url: '/deerpretty' },
  { id: 1417, title: 'Whimsical Fashion', url: '/deerpretty' },
  { id: 1418, title: 'Fawn Jewelry', url: '/deerpretty' },
  { id: 1419, title: 'Ethereal Beauty Inspiration', url: '/deerpretty' },
  { id: 1420, title: 'Soft Glam', url: '/deerpretty' },
  { id: 1421, title: 'Fox Pretty', url: '/foxpretty' },
  { id: 1422, title: 'What Animal Pretty Are You?', url: '/foxpretty' },
  { id: 1423, title: 'Makeup Tutorial', url: '/foxpretty' },
  { id: 1424, title: 'Beauty Guide', url: '/foxpretty' },
  { id: 1425, title: 'Earthy Tones', url: '/foxpretty' },
  { id: 1426, title: 'Bohemian Style', url: '/foxpretty' },
  { id: 1427, title: 'Rustic Makeup', url: '/foxpretty' },
  { id: 1428, title: 'Fox-Inspired Beauty', url: '/foxpretty' },
  { id: 1429, title: 'Sadie Sink Style', url: '/foxpretty' },
  { id: 1430, title: 'Natural Makeup', url: '/foxpretty' },
  { id: 1431, title: 'Warm Tones', url: '/foxpretty' },
  { id: 1432, title: 'Fox-Themed Makeup', url: '/foxpretty' },
  { id: 1433, title: 'Animal-Inspired Beauty', url: '/foxpretty' },
  { id: 1434, title: 'Copper Eyeshadow', url: '/foxpretty' },
  { id: 1435, title: 'Warm Eye Makeup', url: '/foxpretty' },
  { id: 1436, title: 'bareMinerals', url: '/foxpretty' },
  { id: 1437, title: 'Boho Fashion', url: '/foxpretty' },
  { id: 1438, title: 'Whimsical Style', url: '/foxpretty' },
  { id: 1439, title: 'Redhead Beauty', url: '/foxpretty' },
  { id: 1440, title: 'ISOPIA Lashes', url: '/foxpretty' },
  { id: 1441, title: 'Rose Blush', url: '/foxpretty' },
  { id: 1442, title: 'Berry Lip', url: '/foxpretty' },
  { id: 1443, title: 'Matte Makeup', url: '/foxpretty' },
  { id: 1444, title: 'Golden Highlights', url: '/foxpretty' },
  { id: 1445, title: 'Anthropologie Outfits', url: '/foxpretty' },
  { id: 1446, title: 'Natural Textures', url: '/foxpretty' },
  { id: 1447, title: 'Celebrity Beauty Looks', url: '/foxpretty' },
  { id: 1448, title: 'Bohemian Prints', url: '/foxpretty' },
  { id: 1449, title: 'Fable Accessories', url: '/foxpretty' },
  { id: 1450, title: 'Fox-Themed Jewelry', url: '/foxpretty' },
  { id: 1451, title: 'Paisley Prints', url: '/foxpretty' },
  { id: 1452, title: 'Beauty Influencer Tutorials', url: '/foxpretty' },
  { id: 1453, title: 'Soft Glam', url: '/foxpretty' },
  { id: 1454, title: 'Vibrant Makeup', url: '/foxpretty' },
  { id: 1455, title: 'Boho Chic', url: '/foxpretty' },
  { id: 1456, title: 'Eco-Friendly Makeup', url: '/foxpretty' },
  { id: 1457, title: 'Earthy Aesthetic', url: '/foxpretty' },
  { id: 1458, title: 'Bold Eye Makeup', url: '/foxpretty' },
  { id: 1459, title: 'Natural Contour', url: '/foxpretty' },
  { id: 1460, title: 'Makeup Tips', url: '/foxpretty' },
  { id: 1461, title: 'Beauty Inspiration', url: '/foxpretty' },
  { id: 1462, title: 'Modern Bohemian', url: '/foxpretty' },
  { id: 1463, title: 'Animal Pretty', url: '/foxpretty' },
  { id: 1464, title: 'Beauty Trends', url: '/foxpretty' },
  { id: 1465, title: 'Makeup for Redheads', url: '/foxpretty' },
  { id: 1466, title: 'Free-Spirited Fashion', url: '/foxpretty' },

    ];
  
  
  export default SearchData;
